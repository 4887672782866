<template>
    <div class="radio-wrapper" :class="directionClassName">
        <div
            class="radio-container"
            v-for="(option, index) in options"
            :key="index"
        >
            <div class="radio-input">
                <input
                    type="radio"
                    :id="option.name + `${option.value}`"
                    :name="option.name"
                    :value="option.value"
                    :disabled="option.disabled || disabled"
                    :class="{ disabled: option.disabled || disabled }"
                    v-model="pickedValue"
                />
                <label
                    :for="option.name + `${option.value}`"
                    :class="{ disabled: option.disabled || disabled }"
                    >{{ option.label }}</label
                >
            </div>
            <slot :name="option.name" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RadioButtons',
    emits: ['update:modelValue'],
    props: {
        name: {
            type: String
        },
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: function () {
                return [
                    {
                        label: '',
                        name: '',
                        value: '', //String || Number || Boolean
                        disabled: true //非必填
                    }
                ]
            }
        },
        modelValue: {
            type: String || Number || Boolean,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        layout: {
            type: String,
            default: 'column' //column || row
        }
    },
    methods: {
        changeHandler: function () {
            const modelValue = this.modelValue
            if (modelValue[this.value]) {
                modelValue[this.value] = false
            } else {
                modelValue[this.value] = true
            }

            this.$emit('update:modelValue', modelValue)

            if (this.value === 'checkAll' && event.target.checked)
                this.$setGaEvent('checkAll', 'click-Checkbox')
        }
    },
    computed: {
        id: function () {
            return `${this.name}-${this.value}`
        },
        pickedValue: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        directionClassName: function () {
            const className = {}
            if (this.layout === 'row') className['radio-row'] = true
            else if (this.layout === 'column') className['radio-column'] = true
            return className
        }
    }
}
</script>

<style lang="scss" scoped>
.radio-wrapper {
    display: flex;

    &.radio-row {
        align-items: flex-start;
        flex-direction: row;
        .radio-container {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    &.radio-column {
        flex-direction: column;
    }
    .radio-container {
        margin-bottom: 20px;
        .radio-input {
            display: flex;
            align-items: center;
            input {
                &[type='radio'] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;

                    border: 1px solid $eighth-grey;
                    transition: 0.1s all linear;
                    outline: none;
                    box-shadow: 0px 0px 0 2px $primary-white inset;
                    margin: 0;
                }

                &:checked {
                    border: 1px solid $fourth-blue;
                    background: 3px $fourth-blue;
                }
            }
        }
    }

    label {
        cursor: pointer;
        color: $sixth-black;
        font-size: 14px;
        padding-left: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}
.slot-container {
    display: flex;
}
</style>
