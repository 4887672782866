<template>
    <div class="table-wrapper">
        <transition name="fade" mode="out-in">
            <div v-if="!isLoading && selectedCount > 0" class="selected-count">
                您已選取了<span>{{ $numberWithComma(selectedCount) }}</span
                >筆未完成通知
            </div>
        </transition>
        <Table
            :isLoading="isLoading"
            :columnConfig="tableColumns"
            :rows="tableShowData"
            :checkboxName="`Notification`"
            v-model:sortDefault="sortValue"
            :isFocusRow="true"
            :toolbox="['columnSetting', 'zooming']"
            :isPagination="true"
            :style="modeStyle"
            emptyMessage="目前沒有待辦通知"
            v-model="selectedNotices"
            class="table"
            ref="table"
            :columnsMode="mode"
            :isFixedHeader="true"
        >
            <template v-if="$isMobile()">
                <Checkbox
                    name="notificationAll"
                    value="all"
                    label="全選"
                    :disabled="payload.isChecked"
                    v-model="selectedNoticesAll"
                    @click.stop
                />
                <div class="select" @click="showCheckboxModal = true" />
                <Modal
                    class="checkbox-modal"
                    v-model="showCheckboxModal"
                    :isFloat="true"
                    width="150px"
                >
                    <template v-slot:modal-content>
                        <RadioButtons
                            :options="checkboxOptions"
                            v-model="checkboxRadioValue"
                        />
                    </template>
                </Modal>
            </template>
            <template
                v-slot:mobile-header
                v-if="mode === 'self' && $hasPermission('completeNotice')"
            >
                <Checkbox
                    name="notificationAll"
                    value="all"
                    :label="
                        this.checkboxRadioValue === 'all'
                            ? '選取全部通知'
                            : '選取當頁通知'
                    "
                    v-model="selectedNoticesAll"
                    @click.stop
                />
                <div class="select" @click="showCheckboxModal = true" />
                <Modal
                    class="checkbox-modal"
                    v-model="showCheckboxModal"
                    :isFloat="true"
                    width="120px"
                >
                    <template v-slot:modal-content>
                        <RadioButtons
                            :options="checkboxOptions"
                            v-model="checkboxRadioValue"
                        />
                    </template>
                </Modal>
            </template>
            <template v-slot:complete="{ isHeader, row }">
                <div v-if="isHeader" class="header-checkbox">
                    <Checkbox
                        name="notificationAll"
                        value="all"
                        :disabled="payload.isChecked"
                        v-model="selectedNoticesAll"
                        @click.stop
                    />
                    <template v-if="payload.isChecked === true">
                        <div
                            class="select"
                            @click="showCheckboxModal = true"
                            :style="{ opacity: 0.4, cursor: 'not-allowed' }"
                        />
                        <Modal
                            class="checkbox-modal"
                            width="150px"
                            :isFloat="true"
                        >
                            <template v-slot:modal-content>
                                <RadioButtons
                                    :options="checkboxOptions"
                                    v-model="checkboxRadioValue"
                                />
                            </template>
                        </Modal>
                    </template>
                    <template v-else>
                        <div class="select" @click="showCheckboxModal = true" />
                        <Modal
                            class="checkbox-modal"
                            width="150px"
                            :isFloat="true"
                            v-model="showCheckboxModal"
                        >
                            <template v-slot:modal-content>
                                <RadioButtons
                                    :options="checkboxOptions"
                                    v-model="checkboxRadioValue"
                                />
                            </template>
                        </Modal>
                    </template>
                </div>
                <div v-else>
                    <Checkbox
                        v-if="!row.checkedAt"
                        name="notification"
                        :value="row.id"
                        :label="this.$isMobile() ? '未完成' : ''"
                        :style="{ 'color:red': true }"
                        v-model="selectedNotices"
                        @click.stop
                    />
                    <section v-else class="mobile">
                        <span class="success-icon"></span>
                        <span v-if="$isMobile()" class="mobile-title"
                            >已完成</span
                        >
                    </section>
                </div>
            </template>
            <template v-slot:supplierInfo="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    :class="{
                        'supplier-info-btn': $isMobile(),
                        'supplier-info-icon': !$isMobile(),
                        disabled: row.fromSupplierDataStatus === null
                    }"
                    @click.stop="
                        showSupplierInfoModal(
                            row.code,
                            row.fromSupplierDataStatus
                        )
                    "
                />
            </template>
            <template v-slot:notice="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    :class="{
                        'notice-icon': !$isMobile(),
                        'has-event': mode === 'self'
                    }"
                    @click.stop="
                        openNotification({
                            insCode: row.code,
                            noticeId: row.id,
                            manCode: row.manCode
                        })
                    "
                >
                    <!-- todo 待後端討論後可能加回 -->
                    <!-- <div class="notice-count has-event">
                        {{ getShowNoticeCount(row) }}
                    </div> -->
                    <Button
                        v-if="$isMobile()"
                        :buttonWord="'保單資訊'"
                        :iconClass="'notice-icon'"
                        :buttonSize="'small'"
                        class="mobile-button"
                    />
                </div>
                <!-- <div
                    v-else-if="!isHeader && getNoticeCount(row) === 0"
                    :class="{
                        'notice-icon': !$isMobile()
                    }"
                    @click.stop="
                        openNotification({
                            insCode: row.code,
                            noticeId: row.id,
                            manCode: row.manCode
                        })
                    "
                >
                    <Button
                        v-if="$isMobile()"
                        :buttonWord="'保單資訊'"
                        :iconClass="'notice-icon'"
                        :buttonSize="'small'"
                        class="mobile-button"
                    />
                </div> -->
            </template>
            <template v-slot:payer="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        v-if="mode === 'self'"
                        class="customer"
                        @click.stop="openCustomer(row.payerCode, row.payer)"
                    >
                        {{ row.payer }}
                    </a>
                    <span v-else v-text="row.payer" />
                </template>
            </template>
            <template v-slot:insured="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        v-if="mode === 'self'"
                        class="customer"
                        @click.stop="openCustomer(row.insuredCode, row.insured)"
                    >
                        {{ row.insured }}
                    </a>
                    <span v-else v-text="row.insured" />
                </template>
            </template>
            <template v-slot:solicitorOri="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    v-html="$cutSolicitorOriByRules(row.solicitorOri, 'device')"
                />
            </template>
            <template v-slot:notifyState="{ isHeader, row }">
                <a
                    v-if="!isHeader"
                    :class="{
                        checked: row.notifyState === '已完成',
                        unchecked: row.notifyState === '未完成'
                    }"
                >
                    {{ row.notifyState }}
                </a>
            </template>
            <template v-slot:documentLinks="{ isHeader, row }">
                <div v-if="isHeader">{{ '附件' }}</div>
                <template v-else>
                    <a
                        v-if="row.docInfos.length > 0"
                        class="download-content-icon"
                        @click.stop="openAttachedModal(true, row)"
                    >
                    </a>
                    <template v-else>
                        <template v-if="!$isMobile()">
                            <a class="not-download-content-icon">
                                <div
                                    class="info-icon"
                                    tooltip="無附件(或查閱影像檔)"
                                    tooltip-touch
                                />
                            </a>
                        </template>
                        <template v-else>
                            <section style="display: flex">
                                <a class="not-download-content-icon" />
                                <span style="color: #aaadb3"
                                    >無附件(或查閱影像檔)</span
                                >
                            </section>
                        </template>
                    </template>
                </template>
            </template>
            <template v-slot:title="{ isHeader, row }">
                <template v-if="!isHeader">
                    <div class="title-content">
                        {{ row?.title
                        }}{{ row?.subject ? '-' + row?.subject : ''
                        }}{{ row?.description ? `(${row.description})` : '' }}
                    </div>
                </template>
            </template>
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Checkbox from '@/components/Checkbox.vue'
import Table from '@/components/Table.vue'
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import RadioButtons from '@/components/RadioButtons.vue'
import {
    notificationSelfColumns,
    notificationOrgColumns
} from '@/assets/javascripts/tia/setting'

export default {
    name: 'NotificationTable',
    emits: [
        'update:selected',
        'get-insurance-id',
        'showsuppliersyncmodal',
        'click-attached-icon'
    ],
    components: {
        Checkbox,
        Table,
        Button,
        RadioButtons,
        Modal
    },
    props: {
        mode: {
            type: String,
            default: 'self'
        },
        selected: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        advanceFilterPayload: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
        // eventList: {
        //     type: Object,
        //     default: function () {
        //         return {}
        //     }
        // }
    },
    methods: {
        // todo 待後端討論後可能加回
        // getShowNoticeCount: function (row) {
        //     const unreadCount = this.getNoticeCount(row)
        //     return unreadCount > 9 ? '9+' : unreadCount // >=10個通知氣泡會跑版
        // },
        // getNoticeCount: function (row) {
        //     const checkedCount = this.eventList[row.code] || 0 // 勾完通知的暫存'
        //     const detailCheckCount =
        //         this.$store.state.detailCheckedCount[row.code] ?? 0
        //     const unreadCount =
        //         row.hasServiceEvent - checkedCount - detailCheckCount
        //     return unreadCount
        // },
        transformData: function (rows) {
            if (rows.length === 0) return []
            return rows.map((row) => {
                /* isVoid(註銷)優先顯示，再判斷已審/未審(isAudit) */
                /* insStatus -> 顯示用 , statusCode -> 過濾用 */
                if (row.isVoid === '1') {
                    row.statusCode = 'void'
                } else
                    row.statusCode = row.isAudit === '1' ? 'audit' : 'process'
                const statusMap = {
                    process: '未審',
                    audit: '已審',
                    void: '註銷'
                }

                row.insStatus = statusMap[row.statusCode]

                /* [險種] */
                /* type -> 顯示用 , jobCode -> 過濾用 */
                if (row.type === '壽險') row.jobCode = 'life'
                else if (row.type === '產險') row.jobCode = 'property'
                else if (row.type === '團險及旅平險') {
                    row.jobCode = 'group'
                    row.type = '團/旅平險'
                }
                /* [通知狀態] */
                /* 根據checkedAt（完成日期） 去做判斷 */
                row.notifyState = row.checkedAt ? '已完成' : '未完成'

                //假資料：在後端上功能後移除
                row.hasServiceEvent = 13

                return row
            })
        },
        openCustomer: function (code, name) {
            if (!code) return

            this.$setGaEvent('goSearchCustomer', 'click-Link')
            let crossPagePayload = { ...this.payload }
            crossPagePayload.customerCode = code
            crossPagePayload.customerName = name

            /* 移除只用於通知查詢的參數 */
            delete crossPagePayload.notifyDateEnd
            delete crossPagePayload.notifyDateStart
            delete crossPagePayload.notifyGroupType
            delete crossPagePayload.isOrg
            delete crossPagePayload.isChecked

            this.$crossPageFilter('TiaCustomer', crossPagePayload)
        },
        openNotification: function (val) {
            this.$emit('get-insurance-id', val)
            this.$setGaEvent('showNoticeModal', 'click-Icon')
        },
        openAttachedModal: function (isClick, rowData) {
            this.$emit('click-attached-icon', isClick, rowData.docInfos)
            this.$setGaEvent('showAttachedModal', 'click-Icon')
        },
        showSupplierInfoModal: function (val, status) {
            if (status === null) return
            this.$emit('showsuppliersyncmodal', val)
            this.$setGaEvent('showSupplierSyncModal', 'click-Icon') //GA
        },
        setEachOptionChecked: function (isChecked) {
            this.$nextTick(() => {
                // 這邊要拿Table元件內過濾過後的List，否則按全選會讓過濾掉的選項也被勾到
                const filteredRows =
                    this.checkboxRadioValue === 'all'
                        ? this.getFilteredRows()
                        : this.getCurrentRows()
                filteredRows.forEach((row) => {
                    if (!row.checkedAt) {
                        this.selectedNotices[row.id] = isChecked
                    } else {
                        delete this.selectedNotices[row.id]
                    }
                })
            })
        },
        getFilteredRows: function () {
            const checkedList = this.$refs.table?.filterCheckedList
            const isFilterSetList = this.$refs.table?.isFilterSetList
            const filteredRows = this.tableShowData.filter((row) => {
                return Object.keys(checkedList).every((columnKey) => {
                    if (isFilterSetList[columnKey]) {
                        const value = row[columnKey]
                        const isChecked = checkedList[columnKey][value]
                        return isChecked
                    } else {
                        return true
                    }
                })
            })
            return filteredRows
        },
        getCurrentRows: function () {
            return this.$refs.table?.tableShowData ?? []
        }
    },
    computed: {
        showCurrentNotice: function () {
            return Object.keys(this.selectedNotices).length
        },
        selectedNotices: {
            get() {
                return this.selected
            },
            set(val) {
                this.$emit('update:selected', val)
            }
        },
        selectedNoticesAll: {
            get() {
                let selectedALL = null
                // let selectedDone = true
                //current(default): 拿Table元件內當前頁面的List
                //all: 拿Table元件內所有頁面的List
                const filteredRows =
                    this.checkboxRadioValue === 'all'
                        ? this.getFilteredRows()
                        : this.getCurrentRows()

                //舊版
                if (
                    this.selectedCount === this.showCurrentNotice &&
                    this.payload.isChecked === selectedALL
                ) {
                    return {
                        all: true
                    }
                } else {
                    const shouldChecked = filteredRows.every((row) => {
                        return this.selectedNotices[row.id]
                    })
                    return { all: shouldChecked }
                }
            },
            set(val) {
                this.setEachOptionChecked(val.all)
            }
        },
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        modeStyle: function () {
            return {
                '--card-ins-status-dash':
                    this.mode === 'self'
                        ? '1px dashed rgb(196, 197, 204)'
                        : 'none',
                '--card-ins-status-padding-top':
                    this.mode === 'self' ? '10px' : '0px'
            }
        },
        selectedCount: function () {
            return Object.values(this.selectedNotices).filter((checked) => {
                return checked
            }).length
        }
    },
    watch: {
        tableShowData: {
            handler() {
                this.selectedNotices = {}
                this.setEachOptionChecked(false)
            },
            immediate: true
        },
        checkboxRadioValue: function () {
            this.selectedNotices = {}
            this.setEachOptionChecked(true)
        }
    },
    data() {
        return {
            checkboxOptions: [
                {
                    label: '當頁未完成通知',
                    value: 'current'
                },
                {
                    label: '全部未完成通知',
                    value: 'all'
                }
            ],
            showCheckboxModal: false,
            checkboxRadioValue: 'current',
            sortValue: { sort: 'desc', sortBy: 'notifyDate' },
            tableColumns: {}
        }
    },
    mounted() {
        const selfColumns = _.cloneDeep(notificationSelfColumns)
        if (!this.$hasPermission('completeNotice')) {
            delete selfColumns?.complete
        }
        this.tableColumns =
            this.mode === 'self' ? selfColumns : notificationOrgColumns
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/icon.scss';

:deep(.empty-result-icon) {
    @extend .thumbs-up-icon;
}

:deep(.supplier-info) {
    .supplier-info-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
:deep(.notify-state) {
    .checked {
        color: $primary-green;
    }
    .unchecked {
        color: $primary-red;
    }
}
:deep(.document-links) {
    position: relative;
    .download-content-icon {
        margin-right: 0;
    }
    .not-download-content-icon {
        position: relative;
        width: 30px;
        height: 20px;
        cursor: pointer;
    }
    .info-icon {
        position: absolute;
        width: 14px;
        height: 14px;
        top: -25%;
        left: 55%;
    }
}
:deep(.complete) {
    .success-icon {
        height: 20px;
        width: 20px;
    }
}

:deep(.notice-icon) {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.customer {
    display: flex;
    text-decoration: underline;
    color: $primary-blue;
    cursor: pointer;
}
.table-wrapper {
    @media screen and (max-width: 576px) {
        :deep(.mobile-header) {
            padding: 0 4px;
        }

        :deep(.card .row-container .row.body) {
            justify-content: space-between;
            .notice {
                order: 0;
                max-width: fit-content;
                margin-left: 5px;
                &.cell {
                    &::before {
                        display: none;
                    }

                    font-size: 16px;
                }
            }
            .document-links {
                order: 1;
                &.cell {
                    padding-top: var(--card-ins-status-padding-top);
                    border-top: var(--card-ins-status-dash);
                }
            }
            .ins-status {
                order: 2;
            }
            .title {
                order: 3;
                &-content {
                    text-align: center;
                    @media screen and(max-width:576px) {
                        max-width: 65%;
                        text-align: start;
                    }
                }
            }
            .notify-date {
                order: 4;
            }
            .checked-at {
                order: 5;
            }
            .notify-group-type-text {
                order: 6;
            }
            .supplier {
                order: 7;
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }
            }
            .ins-no {
                order: 8;
            }
            .payer {
                order: 9;
            }
            .insured {
                order: 10;
            }
            .job-type-text {
                order: 11;
            }
            .solicitor-ori {
                order: 12;
            }
            .notify-state {
                display: none;
            }

            .complete {
                label {
                    color: $primary-red;
                }
                &.cell {
                    max-width: fit-content;
                    min-width: 28%;
                    &::before {
                        display: none;
                    }
                }
                .mobile {
                    display: flex;
                    margin-right: 10px;

                    &-title {
                        color: $primary-green;
                    }
                }
                .success-icon {
                    height: 20px;
                    width: 20px;
                    @media screen and (max-width: 576px) {
                        margin-right: 10px;
                    }
                }
            }

            .supplier-info {
                order: 1;
                &.cell {
                    align-items: center;
                    padding-top: 10px;
                }
            }
        }
        .mobile-button {
            margin-left: 5px;
            background-color: transparent;
            box-shadow: 0 0 0 1px $primary-black inset;
        }
        :deep(.small-button) {
            .button-icon {
                height: 20px;
                width: 20px;
                margin-right: 3px;
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}

//全選選項modal
.select {
    cursor: pointer;
    width: 10px;
}
.header-checkbox {
    display: flex;
    :deep(.checkbox-container) {
        label {
            width: 10px;
            background: red;
            margin: 0 0px 0 0px;
        }
    }
}

.checkbox-modal {
    &.modal {
        position: absolute;
        top: 35px;
        left: 20px;
        @media screen and (max-width: 576px) {
            top: 72px;
            left: 28px;
        }
        :deep(.modal-area) {
            padding: 10px 0;
            .modal-content {
                padding: 0 10px;
            }
        }
        @media screen and (min-width: 0px) {
            &.float {
                // z-index: 1;
                width: fit-content;
                height: fit-content;
            }
        }
        :deep(.modal-size) {
            @media screen and (min-width: 0px) {
                &.float {
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
    :deep(.modal-background) {
        @media screen and (min-width: 0px) {
            &.float {
                background-color: $primary-white;
                padding: 0;
                border-radius: 4px;
                width: fit-content;
                height: fit-content;
            }
        }
    }

    :deep(.radio-container) {
        margin-bottom: 0px;
        padding: 10px 5px;
        &:hover {
            background: $primary-grey;
        }
    }
}

.selected-count {
    cursor: default;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    color: $secondary-grey;
    margin: 0 0px 10px 0px;
    span {
        display: inline-block;
        padding: 0 5px;
        color: $primary-blue;
        font-weight: 600;
    }
    @media screen and (max-width: 575px) {
        position: sticky;
        top: 10%;
        z-index: 2;
        width: 90%;
        box-shadow: 1px 1px -1px;
        background: white;
        margin: 0 auto;
    }
}
</style>
